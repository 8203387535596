import { DateTime } from "luxon"
import { applySnapshot, Instance, SnapshotOut, types } from "mobx-state-tree"
import {
  listingsAddListing,
  listingsDeleteDraftListing,
  listingsGetListing,
  listingsGetListings,
  listingsGetYourBuyerListings,
  listingsGetYourListings,
} from "../../../shared/services"
import { withSetPropAction } from "../withSetPropAction"
import { Listing, ListingModel, ListingRole, ListingSnapshotOut, ListingStatus } from "./Listing"

export const ListingStoreModel = types
  .model("ListingStore")
  .props({
    listings: types.optional(types.array(ListingModel), []),
    activeListingId: types.maybeNull(types.union(types.number, types.string)),
  })
  .actions(withSetPropAction)
  .actions((store) => ({
    reset() {
      applySnapshot(store, {})
    },
  }))
  .actions((store) => ({
    addListing(listing: Listing | ListingSnapshotOut) {
      store.listings.push(listing)
    },
    removeListing(listing: Listing) {
      store.listings.remove(listing)
    },
    checkDuplicateAddress(place_id: string) {
      if (store.listings.find((l) => l.place_id === place_id)) return true
      return false
    },
  }))
  .actions((store) => ({
    async fetchListings({
      listing_id,
      status,
      role,
      limit = 100,
    }: {
      listing_id?: string
      userId?: string
      status?: ListingStatus
      role?: ListingRole | null
      limit?: number
    }) {
      const result: { error: string | null; errorDetails?: string | null } = {
        error: null,
      }

      const response = listing_id
        ? await listingsGetListing({ path: { id: listing_id } })
        : role === ListingRole.OWNER
          ? await listingsGetYourListings({
              query: {
                status,
                limit,
              },
            })
          : role === ListingRole.BUYER
            ? await listingsGetYourBuyerListings({
                query: {
                  status,
                  limit,
                },
              })
            : await listingsGetListings({
                query: {
                  status,
                  limit,
                },
              })

      if (response.error || !response.data) {
        result.error = "Failed to fetch listings"

        if (Array.isArray(response.error.detail)) {
          result.errorDetails = response.error.detail.map((e) => e.msg).join(", ")
        } else {
          result.errorDetails = response.error.detail
        }

        return result
      }

      const data = Array.isArray(response.data) ? response.data : [response.data]
      const newListingData = data.map((listing) => {
        const currentRecord = store.listings.find((l) => l.id === listing.id)

        // Maintain Buyer and Owner roles of records even when fetching public listings
        // if we already know the role, otherwise use the fetched role
        const activeRole =
          currentRecord?.role && [ListingRole.BUYER, ListingRole.OWNER].includes(currentRecord.role)
            ? currentRecord.role
            : (listing.role as ListingRole)

        return ListingModel.create({
          ...listing,
          role: activeRole ?? null,
          status: listing.status as ListingStatus,
          place_id: listing.place_id ?? "",
          owner_id: listing.user_id,
          team_id: listing.team_id,
          team_name: listing.team_name,
          lot_size: listing.lot_size ? listing.lot_size.toString() : null,
          commission: listing.commission ? listing.commission : null,
          created_at: DateTime.fromISO(listing.created_at, { zone: "utc" }).toJSDate(),
          updated_at: listing.updated_at
            ? DateTime.fromISO(listing.updated_at, { zone: "utc" }).toJSDate()
            : null,
          beds: listing.beds ?? 0,
          full_baths: listing.full_baths ?? 0,
          half_baths: listing.half_baths ?? 0,
          total_rooms: listing.total_rooms ?? 0,
          lot_size_unit: listing.lot_size_unit ?? "acres",
          commission_type: listing.commission_type ?? "percent",
          hoa_cadence: listing.hoa_cadence ? listing.hoa_cadence : "monthly",
          image_path: listing.image_path,
        })
      })

      if (listing_id) {
        if (newListingData.length === 0) {
          result.error = "Listing not found"
        } else {
          const listingDataUpdated = [
            ...store.listings.filter((item) => item.id !== listing_id),
            ...newListingData,
          ]
          store.setProp("listings", listingDataUpdated)
        }
      } else {
        const listingDataUpdated = [
          ...store.listings.filter(
            (item) => item.role !== role && !newListingData.find((d) => d.id === item.id),
          ),
          ...newListingData,
        ]
        store.setProp("listings", listingDataUpdated)
      }

      return result
    },

    async deleteListing({ id }: { id: string }) {
      const result: { error: string | null; errorDetails?: string | null } = {
        error: null,
      }

      const { error } = await listingsDeleteDraftListing({ path: { id } })

      if (error) {
        result.error = "Failed to delete listing"
        result.errorDetails = Array.isArray(error.detail)
          ? error.detail.map((e) => e.msg).join(", ")
          : error.detail
      } else {
        const listing = store.listings.find((l) => l.id === id)
        if (listing) {
          store.removeListing(listing)
        }
      }

      return result
    },
  }))
  .actions((store) => ({
    async addListingDraft({
      place_id,
      address,
      street,
      city,
      state,
      country,
      zip_code,
      latitude,
      longitude,
      county,
    }: {
      place_id: string
      address: string
      street: string
      city: string
      state: string
      country: string
      zip_code: string
      latitude: number
      longitude: number
      county: string
    }) {
      const result: { id?: number; error: string | null; errorDetails?: string | null } = {
        error: "Failed to add new listing",
      }

      const response = await listingsAddListing({
        body: {
          status: "draft",
          place_id,
          address,
          street,
          city,
          state,
          country,
          zip_code,
          county,
          latitude,
          longitude,
          beds: 0,
          full_baths: 0,
          half_baths: 0,
          total_rooms: 0,
          lot_size_unit: "acres",
          hoa_cadence: "monthly",
        },
      })

      if (response.error) {
        if (Array.isArray(response.error.detail)) {
          result.errorDetails = response.error.detail.map((e) => e.msg).join(", ")
        } else {
          result.errorDetails = response.error.detail
        }

        return result
      }

      if (response.data && response.data.id) {
        const dbId: string = response.data.id
        const { error, errorDetails } = await store.fetchListings({ listing_id: dbId })
        if (error) {
          result.error = "Failed to refresh after adding listing. Please manually refresh."
          result.errorDetails = errorDetails
        }

        return { id: dbId, error: null }
      }

      return result
    },
  }))
  .views((store) => ({
    get activeListing() {
      if (!store.activeListingId) return undefined
      return store.listings.find((l) => l.id === store.activeListingId)
    },
    listingById(id: string) {
      return store.listings.find((l) => l.id === id)
    },
    sortedListings({ role }: { role?: ListingRole | null }) {
      const sortListings = (a: Listing, b: Listing) =>
        a.created_at === b.created_at ? 0 : a.created_at > b.created_at ? -1 : 1

      if (role === ListingRole.BUYER) {
        return store.listings.filter((l) => l.role === ListingRole.BUYER).sort(sortListings)
      } else {
        const liveListings = store.listings
          .filter((l) => l.status === ListingStatus.LIVE)
          .sort(sortListings)

        const additionalListings = store.listings
          .filter((l) => {
            if (role === ListingRole.OWNER) {
              return l.status !== ListingStatus.LIVE
            } else {
              return l.status !== ListingStatus.LIVE && l.status !== ListingStatus.DRAFT
            }
          })
          .sort(sortListings)

        return [...liveListings, ...additionalListings]
      }
    },
  }))

export interface ListingStore extends Instance<typeof ListingStoreModel> {}
export interface ListingStoreSnapshot extends SnapshotOut<typeof ListingStoreModel> {}
