import { Instance, SnapshotOut, applySnapshot, types } from "mobx-state-tree"
import { withSetPropAction } from "../withSetPropAction"
import { LocationModel } from "./Location"
import { UserModel } from "./User"

export enum MsgStatus {
  SUCCESS = "success",
  ERROR = "error",
}

export const StatusModel = types.model("StatusStore").props({
  msg: types.maybeNull(types.string),
  type: types.maybe(types.enumeration("Type", ["success", "error"])),
  top: types.maybe(types.boolean),
})

export const AccountStoreModel = types
  .model("AccountStore")
  .props({
    user: types.optional(UserModel, {}),
    darkMode: types.optional(types.boolean, false),
    locationGranted: types.maybe(types.boolean),
    location: types.optional(LocationModel, {}),
    accountProvider: types.maybe(types.enumeration("Provider", ["apple", "google"])),
    reviewRequested: false,
    pushToken: types.maybeNull(types.string),
    localAuthEnabled: types.optional(types.boolean, false),
    localAuthed: types.optional(types.boolean, true),
    loaded: false,
    status: types.maybeNull(StatusModel),
    selling: false,
    dictionaryLookup: types.maybeNull(types.string),
    animation: types.maybeNull(types.enumeration("Animation", ["confetti"])),
  })
  .actions(withSetPropAction)
  .actions((store) => ({
    reset() {
      applySnapshot(store, {})
    },
  }))

export interface AccountStore extends Instance<typeof AccountStoreModel> {}
export interface AccountStoreSnapshot extends SnapshotOut<typeof AccountStoreModel> {}
