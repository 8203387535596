import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { chatsAddMessageReaction, chatsRemoveMessageReaction } from "../../../shared/services"
import { withSetPropAction } from "../withSetPropAction"

const MsgReactionModel = types.model("MsgReaction").props({
  created_at: types.Date,
  user_id: types.string,
  emoji: types.string,
})

export const MsgModel = types
  .model("Msg")
  .props({
    id: types.identifier,
    text: types.string,
    created_at: types.Date,
    updated_at: types.maybeNull(types.Date),
    user_id: types.string,
    chat_id: types.string,
    attachments: types.maybeNull(types.array(types.string)),
    reactions: types.maybeNull(types.array(MsgReactionModel)),
  })
  .actions(withSetPropAction)
  .actions((store) => ({
    removeLocalReaction({ user_id }: { user_id: string }) {
      if (!store.reactions) return

      store.setProp(
        "reactions",
        store.reactions.filter((r) => r.user_id !== user_id),
      )
    },
  }))
  .actions((store) => ({
    async addReaction({ emoji }: { emoji: string }) {
      const result: {
        error?: string | null
        errorDetails?: string | null
      } = {}

      const { error } = await chatsAddMessageReaction({
        path: { id: store.chat_id, message_id: store.id },
        body: { emoji },
      })

      if (error) {
        result.error = "Failed to add reaction"
        result.errorDetails = Array.isArray(error.detail)
          ? error.detail.map((e) => e.msg).join(", ")
          : error.detail
      }

      return result
    },
    async removeReaction({ user_id }: { user_id: string }) {
      const result: {
        error?: string
        errorDetails?: string
      } = {}

      const { error } = await chatsRemoveMessageReaction({
        path: { id: store.chat_id, message_id: store.id },
      })

      if (error) {
        result.error = "Failed to remove reaction"
        result.errorDetails = Array.isArray(error.detail)
          ? error.detail.map((e) => e.msg).join(", ")
          : error.detail
      } else {
        store.removeLocalReaction({ user_id })
      }

      return result
    },
  }))
  .views((store) => ({
    otherUserReactions(user_id: string) {
      if (!store.reactions) return []
      return store.reactions.filter((r) => r.user_id !== user_id)
    },
    currentUserReaction(user_id: string) {
      if (!store.reactions) return null
      const reaction = store.reactions.find((r) => r.user_id === user_id)

      return reaction ?? null
    },
  }))

export interface Msg extends Instance<typeof MsgModel> {}
export interface MsgSnapshot extends SnapshotOut<typeof MsgModel> {}

declare type renderFunction = (x: any) => JSX.Element

export interface IMsgUser {
  _id: string | number
  name?: string
  avatar?: string | number | renderFunction | undefined
}

export interface IMsg {
  _id: string | number
  text: string
  createdAt: Date | number
  user: IMsgUser
  image?: string
  system?: boolean
  sent?: boolean
  received?: boolean
  pending?: boolean
}
