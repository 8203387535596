import { useEffect, useState } from 'react'
import { useStores } from '../models'
import { useNotifyNav } from './useNotifications'

export function useBrowserNotify() {
  const rootStore = useStores()
  const { notificationStore } = rootStore
  const notifyNav = useNotifyNav()
  const [browserNotifications, setBrowserNotifications] = useState<
    { notificationId?: string; browserNotification: globalThis.Notification }[]
  >([])
  const realtimeNotificationId: string | null =
    notificationStore.realtimeNotificationId

  useEffect(() => {
    async function sendNotification() {
      if (!realtimeNotificationId) return

      const realtimeNotification = notificationStore.notificationById(
        realtimeNotificationId
      )
      if (!realtimeNotification) return

      realtimeNotification.setProp('realtime', false)

      // Check if browser notification access is allowed
      if (!('Notification' in window)) return
      const permission = await window.Notification.requestPermission()
      if (permission !== 'granted') return

      const browserNotification = new window.Notification(
        realtimeNotification.title,
        {
          body: realtimeNotification.body || undefined,
        }
      )
      if (!browserNotification) return

      setBrowserNotifications(current => [
        ...current,
        { notify: realtimeNotification.id, browserNotification },
      ])

      browserNotification.addEventListener('click', async () => {
        const clickNotification = notificationStore.notificationById(
          realtimeNotificationId
        )

        if (clickNotification) {
          const { error, errorDetails } =
            await notificationStore.ackNotifications({
              ids: [clickNotification.id],
            })

          if (error) {
            console.error(errorDetails)
          }

          notifyNav({
            screen: clickNotification.screen || undefined,
            active_id: clickNotification.active_id || undefined,
          })
        }

        browserNotification.close()
      })
    }

    if (realtimeNotificationId) {
      sendNotification()
    }
  }, [notificationStore, notifyNav, realtimeNotificationId])

  useEffect(() => {
    // Close the browser notification if it has been acked somewhere else
    if (browserNotifications.length > 0) {
      const closedIds: string[] = []
      for (const entry of browserNotifications) {
        if (entry.notificationId) {
          const notify = notificationStore.notificationById(
            entry.notificationId
          )
          if (notify?.ack) {
            entry.browserNotification.close()
            closedIds.push(entry.notificationId)
          }
        }
      }

      if (closedIds.length > 0) {
        setBrowserNotifications(
          browserNotifications.filter(
            n => n.notificationId && !closedIds.includes(n.notificationId)
          )
        )
      }
    }
  }, [notificationStore, notifyNav, browserNotifications])

  return null
}
