import { DateTime } from "luxon"
import { applySnapshot, Instance, SnapshotOut, types } from "mobx-state-tree"
import {
  listingsTourAvailability,
  toursGetTours,
  toursRequestTour,
  USTimezone,
} from "../../services/client"
import { withSetPropAction } from "../withSetPropAction"
import { ListingSlotModel } from "./ListingSlot"
import { Tour, TourModel, TourStatus } from "./Tour"

export const TourStoreModel = types
  .model("TourStore")
  .props({
    tours: types.array(TourModel),
    listingSlots: types.array(ListingSlotModel),
  })
  .actions(withSetPropAction)
  .actions((store) => ({
    reset() {
      applySnapshot(store, {})
    },
    addTour(doc: Tour) {
      store.tours.push(doc)
    },
    deleteTour(doc: Tour) {
      store.tours.remove(doc)
    },
  }))
  .actions((store) => ({
    async fetchTours({ listing_id }: { listing_id?: string }) {
      const result: { error: string | null; errorDetails?: string | null; buyerIds: string[] } = {
        error: null,
        buyerIds: [],
      }

      const { error, data } = await toursGetTours({
        query: {
          listing_id,
          limit: 100,
        },
      })

      if (error) {
        result.error = "Failed to fetch tours"
        result.errorDetails = Array.isArray(error.detail)
          ? error.detail.map((e) => e.msg).join(", ")
          : error.detail

        return result
      } else {
        const userIds: string[] = []
        for (const record of data) {
          if (!userIds.includes(record.user_id)) {
            userIds.push(record.user_id)
          }
        }
        result.buyerIds = userIds

        const newData = data.map((tour) => {
          return TourModel.create({
            ...tour,
            userId: tour.user_id,
            start: DateTime.fromISO(tour.start, { zone: "utc" }).toJSDate(),
            end: DateTime.fromISO(tour.end, { zone: "utc" }).toJSDate(),
            created_at: DateTime.fromISO(tour.created_at, { zone: "utc" }).toJSDate(),
            updated_at: tour.updated_at
              ? DateTime.fromISO(tour.updated_at, { zone: "utc" }).toJSDate()
              : null,
          })
        })

        if (listing_id) {
          if (newData.length > 0) {
            const dataUpdated = [
              ...store.tours.filter((item) => item.listing_id !== listing_id),
              ...newData,
            ]

            store.setProp("tours", dataUpdated)
          }
        } else {
          store.setProp("tours", newData)
        }
      }

      return result
    },
  }))
  .actions((store) => ({
    async getTourAvailability({ listing_id }: { listing_id: string }) {
      const result: { error: string | null; errorDetails?: string | null; slots: string[] } = {
        error: null,
        slots: [],
      }

      const teamRes = await listingsTourAvailability({
        path: { id: listing_id },
        query: { timezone: DateTime.now().toFormat("z") as USTimezone },
      })

      if (teamRes.error) {
        result.error = "Failed to fetch tour availability"

        if (Array.isArray(teamRes.error.detail)) {
          result.errorDetails = teamRes.error.detail.map((e) => e.msg).join(", ")
        } else {
          result.errorDetails = teamRes.error.detail
        }

        return result
      } else {
        const slots = teamRes.data.slots.sort((a, b) => a.localeCompare(b))
        store.setProp(
          "listingSlots",
          slots.map((entry) => ListingSlotModel.create({ listing_id, start: entry })),
        )

        return {
          error: null,
          slots,
        }
      }
    },
  }))
  .actions((store) => ({
    async submitTourRequest({
      listing_id,
      startIso,
      endIso,
    }: {
      listing_id: string
      startIso: string
      endIso: string
    }) {
      const result: { error: string | null; errorDetails?: string | null } = {
        error: "Failed to submit tour",
      }

      const tz = DateTime.now().toFormat("z")
      const { error } = await toursRequestTour({
        body: {
          listing_id,
          start: startIso,
          end: endIso,
          timezone: tz as USTimezone,
        },
      })

      if (error) {
        result.errorDetails = Array.isArray(error.detail)
          ? error.detail.map((e) => e.msg).join(", ")
          : error.detail
      } else {
        await store.fetchTours({ listing_id })
        result.error = null
      }

      return result
    },
  }))
  .views((store) => ({
    latestListingTour(listing_id: string) {
      const now = DateTime.now()

      const sortedTours = store.tours
        .filter((l) => l.listing_id === listing_id && DateTime.fromJSDate(l.end) > now)
        .sort((a, b) => (a.created_at === b.created_at ? 0 : a.created_at > b.created_at ? -1 : 1))

      return sortedTours.length > 0 ? sortedTours[0] : null
    },
    tourById(id: string) {
      return store.tours.find((o) => o.id === id)
    },
    listingAvailableSlots(listing_id: string) {
      const now = DateTime.now()
      const slotDates: { [key: string]: DateTime[] } = {}

      for (const l of store.listingSlots) {
        if (l.listing_id !== listing_id) {
          continue
        }

        const start = DateTime.fromISO(l.start)
        if (start <= now) {
          continue
        }

        const startIsoDate = start.toISODate()
        if (!startIsoDate) {
          continue
        }

        if (!slotDates[startIsoDate]) {
          slotDates[startIsoDate] = [start]
        } else {
          slotDates[startIsoDate].push(start)
        }
      }

      return slotDates
    },
    listingUserTours(listing_id?: string) {
      const now = DateTime.now()

      const tours = listing_id
        ? store.tours.filter((l) => l.listing_id === listing_id)
        : store.tours

      const usertours: { [key: string]: UserTour } = {}
      for (const record of tours) {
        const existingTour = usertours[record.userId]

        if (existingTour) {
          const existingTourUpdateDt = existingTour.updated_at
            ? existingTour.updated_at
            : existingTour.created_at
          const recordUpdateDt = record.updated_at ? record.updated_at : record.created_at

          if (recordUpdateDt < existingTourUpdateDt) {
            continue
          }
        }

        usertours[record.userId] = { ...record, past: DateTime.fromJSDate(record.end) < now }
      }

      return Object.values(usertours).sort((a, b) =>
        a.start === b.start ? 0 : a.start > b.start ? -1 : 1,
      )
    },
  }))
  .views((store) => ({
    pendingTours(listing_id?: string) {
      const pendingOffers = store
        .listingUserTours(listing_id)
        .filter((l) => l.status === TourStatus.REQUESTED)

      return listing_id ? pendingOffers.filter((l) => l.listing_id === listing_id) : pendingOffers
    },
  }))

export interface TourStore extends Instance<typeof TourStoreModel> {}
export interface TourStoreSnapshot extends SnapshotOut<typeof TourStoreModel> {}

interface UserTour extends Tour {
  past: boolean
}
