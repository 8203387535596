import { Box, Paper, Typography } from '@mui/material'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import Markdown from 'react-markdown'
import { Msg } from '../../../../../shared/models'
import { useTheme } from '../../../theme'
import './md.css'

interface MessageProps {
  userId: string
  message: Msg
}

export const Message: React.FC<MessageProps> = observer(
  ({ userId, message }) => {
    const [createdTimeAgo, setCreatedTimeAgo] = useState(
      DateTime.fromJSDate(message.created_at).toRelative()
    )
    const seller = message.user_id !== userId
    const theme = useTheme()

    useEffect(() => {
      // Refresh time ago every 10 seconds
      const intervalId = setInterval(() => {
        setCreatedTimeAgo(DateTime.fromJSDate(message.created_at).toRelative())
      }, 10 * 1000)

      return () => {
        clearInterval(intervalId)
      }
    }, [message.created_at])

    const colors = seller
      ? {
          background: theme.palette.background.default,
          text: theme.palette.text.primary,
        }
      : {
          background: theme.palette.primary.main,
          text: theme.palette.primary.contrastText,
        }

    return (
      <Paper
        variant='outlined'
        sx={{
          backgroundColor: colors.background,
          p: 1,
          width: '80%',
          alignSelf: seller ? 'flex-start' : 'flex-end',
          borderRadius: 3,
        }}
      >
        <Box sx={{ color: colors.text }}>
          <Markdown className='reactMarkDown'>{message.text}</Markdown>
        </Box>
        <Typography variant='caption' color={colors.text}>
          {createdTimeAgo}
        </Typography>
      </Paper>
    )
  }
)
