import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { chatsSendAiChatMessage, chatsSendChatMessage } from "../../services"
import { withSetPropAction } from "../withSetPropAction"

export enum ChatTypeEnum {
  BUYER = "buyer",
  TEAM = "team",
  AI = "ai",
}

export const ChatModel = types
  .model("Chat")
  .props({
    id: types.identifier,
    created_at: types.Date,
    updated_at: types.maybeNull(types.Date),
    user_id: types.string,
    type: types.enumeration("ChatType", Object.values(ChatTypeEnum)),
    listing_id: types.maybeNull(types.string),
    preview: types.maybeNull(types.string),
    team_id: types.maybeNull(types.string),
    buyer_user_id: types.maybeNull(types.string),
    hasEarlierMessages: false,
    isLoadingEarlier: false,
    total: types.maybeNull(types.number),
    activeReactionMsgId: types.maybeNull(types.string),
  })
  .actions(withSetPropAction)
  .actions((store) => ({
    async sendMessage({ text }: { text: string }) {
      const result: { error: string | null; errorDetails?: string | null } = {
        error: "Failed to send message",
      }

      const { error } = await chatsSendChatMessage({ path: { id: store.id }, body: { text } })
      if (error) {
        result.errorDetails = Array.isArray(error.detail)
          ? error.detail.map((e) => e.msg).join(", ")
          : error.detail

        return result
      } else {
        return { error: null }
      }
    },
    async sendAIMessage({ text }: { text: string }) {
      const result: { error: string | null; errorDetails?: string | null } = {
        error: "Failed to send message",
      }

      const { error } = await chatsSendAiChatMessage({ path: { id: store.id }, body: { text } })
      if (error) {
        result.errorDetails = Array.isArray(error.detail)
          ? error.detail.map((e) => e.msg).join(", ")
          : error.detail

        return result
      } else {
        return { error: null }
      }
    },
  }))

export interface Chat extends Instance<typeof ChatModel> {}
export interface ChatSnapshot extends SnapshotOut<typeof ChatModel> {}
