import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { withSetPropAction } from "../withSetPropAction"

export enum Features {
  SELLING = "selling",
}

export enum FeatureStatus {
  ACTIVE = "active",
  REQUESTED = "requested",
}

export const FeaturesModel = types.model("Features").props({
  selling: types.maybeNull(
    types.enumeration(
      "FeatureStatus",
      Object.values(FeatureStatus).map((v) => `${v}`),
    ),
  ),
})

export const ProfileModel = types
  .model("Profile")
  .props({
    user_id: types.identifier,
    name: types.maybeNull(types.string),
    pic: types.maybeNull(types.string),
    agent_license: types.maybeNull(types.number),
    features: types.maybeNull(FeaturesModel),
  })
  .actions(withSetPropAction)

export interface Profile extends Instance<typeof ProfileModel> {}
export interface ProfileSnapshot extends SnapshotOut<typeof ProfileModel> {}
