import { DateTime } from "luxon"
import { Instance, SnapshotOut, applySnapshot, types } from "mobx-state-tree"
import {
  chatsGetChatMessageFiles,
  listingsFileSignedUrl,
  listingsGetFiles,
  listingsGetPublicFiles,
  userGetFunds,
} from "../../services"
import { withSetPropAction } from "../withSetPropAction"
import { FileModel, FileStatus } from "./File"

export const FileStoreModel = types
  .model("FileStore")
  .props({
    files: types.array(FileModel),
  })
  .actions(withSetPropAction)
  .actions((store) => ({
    reset() {
      applySnapshot(store, {})
    },
    removeLocalFunds() {
      store.setProp("files", [...store.files.filter((d) => d.type !== "funds")])
    },
  }))
  .actions((store) => ({
    async fetchFiles({
      listing_id,
      publicOnly = false,
      limit = 100,
    }: {
      listing_id: string
      publicOnly?: boolean
      limit?: number
    }) {
      const result: { error: string | null; errorDetails?: string | null } = { error: null }

      const { error, data } = publicOnly
        ? await listingsGetPublicFiles({ path: { id: listing_id }, query: { limit } })
        : await listingsGetFiles({ path: { id: listing_id }, query: { limit } })

      if (error) {
        result.error = "Failed to fetch files"

        if (Array.isArray(error.detail)) {
          result.errorDetails = error.detail.map((e) => e.msg).join(", ")
        } else {
          result.errorDetails = error.detail
        }
      }

      if (data) {
        const fileData = data.map((file) => {
          return FileModel.create({
            ...file,
            created_at: DateTime.fromISO(file.created_at, { zone: "utc" }).toJSDate(),
            updated_at: file.updated_at
              ? DateTime.fromISO(file.updated_at, { zone: "utc" }).toLocal().toJSDate()
              : null,
            prev_versions: file.prev_versions
              ? file.prev_versions.map((v) => ({
                  ...v,
                  updated_at: DateTime.fromISO(v.updated_at).toJSDate(),
                }))
              : [],
          })
        })

        store.setProp("files", [
          ...store.files.filter((d) => d.listing_id !== listing_id),
          ...fileData,
        ])
      }

      return result
    },
    async fetchChatFiles({ chat_id }: { chat_id: string }) {
      const result: { error: string | null; errorDetails?: string | null } = { error: null }

      const { error, data } = await chatsGetChatMessageFiles({ path: { id: chat_id } })
      if (error) {
        result.error = "Failed to fetch chat files"
        result.errorDetails = Array.isArray(error.detail)
          ? error.detail.map((e) => e.msg).join(", ")
          : error.detail
      }

      if (data) {
        const fileData = data.map((file) => {
          return FileModel.create({
            ...file,
            created_at: DateTime.fromISO(file.created_at, { zone: "utc" }).toJSDate(),
            updated_at: file.updated_at
              ? DateTime.fromISO(file.updated_at, { zone: "utc" }).toLocal().toJSDate()
              : null,
            prev_versions: file.prev_versions
              ? file.prev_versions.map((v) => ({
                  ...v,
                  updated_at: DateTime.fromISO(v.updated_at).toJSDate(),
                }))
              : [],
          })
        })

        store.setProp("files", [
          ...store.files.filter((d) => !fileData.find((f) => f.id === d.id)),
          ...fileData,
        ])
      }

      return result
    },
    async fetchCurrentUserFunds({ include_url = false }: { include_url: boolean }) {
      const result: { error: string | null; errorDetails?: string | null; url?: string | null } = {
        error: null,
      }

      const { error, data } = await userGetFunds({ query: { include_url } })

      if (error && error.detail !== "Not found") {
        result.error = "Failed to fetch funds"
        if (Array.isArray(error.detail)) {
          result.errorDetails = error.detail.map((e) => e.msg).join(", ")
        } else {
          result.errorDetails = error.detail
        }
      }

      if (data) {
        const fileData = FileModel.create({
          ...data,
          created_at: DateTime.fromISO(data.created_at, { zone: "utc" }).toJSDate(),
          updated_at: data.updated_at
            ? DateTime.fromISO(data.updated_at, { zone: "utc" }).toLocal().toJSDate()
            : null,
          prev_versions: data.prev_versions
            ? data.prev_versions.map((v) => ({
                ...v,
                updated_at: DateTime.fromISO(v.updated_at).toJSDate(),
              }))
            : [],
        })

        store.setProp("files", [...store.files.filter((d) => d.id !== fileData.id), fileData])
      } else {
        store.removeLocalFunds()
      }

      return result
    },
    async fundsDocUrl({ listing_id, file_id }: { listing_id: string; file_id: string }) {
      const result: { error: string | null; errorDetails?: string | null; url: string | null } = {
        error: null,
        url: null,
      }

      const { error, data } = await listingsFileSignedUrl({ path: { id: listing_id, file_id } })

      if (error) {
        result.error = "Failed to get URL"
        result.errorDetails = Array.isArray(error.detail)
          ? error.detail.map((e: any) => e.msg).join(", ")
          : error.detail
      }

      if (data) {
        result.url = data.url
      }

      return result
    },
  }))
  .views((store) => ({
    listingFiles({
      listing_id,
      status,
      images,
    }: {
      listing_id: string
      status?: FileStatus
      images?: boolean
    }) {
      const files = store.files
        .filter((d) => {
          if (d.listing_id !== listing_id) return false

          if (status) {
            if (d.status !== status) return false
            if (status === FileStatus.SHARED && d.type === "offer") return false
          }

          if (images === true) {
            if (d.image !== true) return false
          } else if (images === false) {
            if (d.image !== false) return false
          }

          return true
        })
        .sort((a, b) => (a.created_at === b.created_at ? 0 : a.created_at > b.created_at ? -1 : 1))

      return files
    },
    fileById(id: string) {
      return store.files.find((n) => n.id === id)
    },
    get fileUserIds() {
      const userIds: string[] = []
      for (const file of store.files) {
        if (!userIds.includes(file.user_id)) {
          userIds.push(file.user_id)
        }
      }

      return userIds
    },
    userProofOfFunds(user_id: string) {
      const funds = store.files.find((f) => f.user_id === user_id && f.type === "funds")
      return funds ?? null
    },
  }))

export interface FileStore extends Instance<typeof FileStoreModel> {}
export interface FileStoreSnapshot extends SnapshotOut<typeof FileStoreModel> {}
