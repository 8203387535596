import { DateTime } from "luxon"
import { Instance, SnapshotOut, types } from "mobx-state-tree"
import {
  chatsCreateChat,
  chatsGetChatMessages,
  chatsGetChats,
  chatsGetMessage,
  userBulkFetchUserProfiles,
} from "../../services"
import { ProfileModel } from "../Account/Profile"
import { withSetPropAction } from "../withSetPropAction"
import { ChatModel, ChatTypeEnum } from "./Chat"
import { MsgModel } from "./Msg"

export const ChatStoreModel = types
  .model("ChatStore")
  .props({
    chats: types.optional(types.array(ChatModel), []),
    messages: types.optional(types.array(MsgModel), []),
    profiles: types.optional(types.array(ProfileModel), []),
    activeChatId: types.maybeNull(types.string),
  })
  .actions(withSetPropAction)
  .actions((store) => ({
    async getProfiles({ userIds, refresh = false }: { userIds: string[]; refresh?: boolean }) {
      const result: {
        errorDetails?: string | null
        profiles: { [key: string]: { name?: string; pic?: string; agent_license?: number | null } }
      } = { profiles: {} }

      const newUserIds: string[] = []

      if (refresh) {
        newUserIds.push(...userIds)
      } else {
        for (const userId of userIds) {
          const profile = store.profiles.find((p) => p.user_id === userId)
          if (profile) {
            result.profiles[userId] = {
              name: profile.name || undefined,
              pic: profile.pic || undefined,
            }
          } else {
            newUserIds.push(userId)
          }
        }
      }

      if (newUserIds.length > 0) {
        const { data, error } = await userBulkFetchUserProfiles({ body: { ids: newUserIds } })

        if (error) {
          result.errorDetails = Array.isArray(error.detail)
            ? error.detail.map((e) => e.msg).join(", ")
            : error.detail
        } else if (data) {
          const newRecords = data.map((record) => {
            result.profiles[record.user_id] = {
              name: record.name || undefined,
              pic: record.pic || undefined,
              agent_license: record.agent_license,
            }
            return ProfileModel.create(record)
          })

          if (newRecords.length > 0) {
            if (refresh) {
              store.setProp("profiles", newRecords)
            } else {
              store.setProp("profiles", [...store.profiles, ...newRecords])
            }

            return result
          }
        }
      }

      return result
    },
  }))
  .actions((store) => ({
    async refreshProfiles() {
      const userIds: string[] = []

      for (const prof of store.profiles) {
        if (prof.user_id) {
          userIds.push(prof.user_id)
        }
      }

      await store.getProfiles({ userIds, refresh: true })

      return null
    },
    async fetchChats() {
      const result: { error: string | null; errorDetails?: string | null } = {
        error: "Failed to fetch chats",
      }

      const { error, data } = await chatsGetChats()

      if (error) {
        result.errorDetails = Array.isArray(error.detail)
          ? error.detail.map((e) => e.msg).join(", ")
          : error.detail

        return result
      } else {
        const chats = data.chats
          .map((record) => {
            return ChatModel.create({
              ...record,
              created_at: DateTime.fromISO(record.created_at, { zone: "utc" }).toJSDate(),
              updated_at: record.updated_at
                ? DateTime.fromISO(record.updated_at, { zone: "utc" }).toJSDate()
                : null,
              type: record.type as ChatTypeEnum,
            })
          })
          .sort((a, b) =>
            a.created_at === b.created_at ? 0 : a.created_at > b.created_at ? 1 : -1,
          )

        const newProfiles = data.users.map((user) => ProfileModel.create(user))
        store.setProp("profiles", [
          ...store.profiles.filter(
            (profile) => !newProfiles.find((newProfile) => newProfile.user_id === profile.user_id),
          ),
          ...newProfiles,
        ])

        store.setProp("chats", chats)

        return { error: null }
      }
    },
    async fetchMessages({
      chatId,
      limit = 20,
      skip,
    }: {
      chatId: string
      limit?: number
      skip?: number
    }) {
      const result: {
        error: string | null
        errorDetails?: string | null
        count: number
        total: number
      } = {
        error: "Failed to fetch chats",
        count: 0,
        total: 0,
      }

      const { error, data } = await chatsGetChatMessages({
        path: { id: chatId },
        query: { limit, skip },
      })

      if (error) {
        result.errorDetails = Array.isArray(error.detail)
          ? error.detail.map((e) => e.msg).join(", ")
          : error.detail

        return result
      } else {
        const fetchedMessages = data.messages.map((record) => {
          return MsgModel.create({
            ...record,
            created_at: DateTime.fromISO(record.created_at, { zone: "utc" }).toJSDate(),
            updated_at: record.updated_at
              ? DateTime.fromISO(record.updated_at, { zone: "utc" }).toJSDate()
              : null,
            reactions: record.reactions?.map((r) => ({
              ...r,
              created_at: DateTime.fromISO(r.created_at, { zone: "utc" }).toJSDate(),
            })),
          })
        })

        const allMessages = [
          ...store.messages.filter((m) => !fetchedMessages.find((c) => c.id === m.id)),
          ...fetchedMessages,
        ].sort((a, b) => (a.created_at === b.created_at ? 0 : a.created_at > b.created_at ? 1 : -1))

        store.setProp("messages", allMessages)

        return { error: null, count: allMessages.length, total: data.total }
      }
    },
    async fetchMessage({ messageId }: { messageId: string }) {
      const result: {
        error: string | null
        errorDetails?: string | null
      } = {
        error: "Failed to fetch message",
      }

      const { error, data } = await chatsGetMessage({
        path: { message_id: messageId },
      })

      if (error) {
        result.errorDetails = Array.isArray(error.detail)
          ? error.detail.map((e) => e.msg).join(", ")
          : error.detail

        return result
      } else {
        const newMsg = MsgModel.create({
          ...data,
          created_at: DateTime.fromISO(data.created_at, { zone: "utc" }).toJSDate(),
          updated_at: data.updated_at
            ? DateTime.fromISO(data.updated_at, { zone: "utc" }).toJSDate()
            : null,
          reactions: data.reactions?.map((r) => ({
            ...r,
            created_at: DateTime.fromISO(r.created_at, { zone: "utc" }).toJSDate(),
          })),
        })

        const allMessages = [...store.messages.filter((m) => m.id !== newMsg.id), newMsg].sort(
          (a, b) => (a.created_at === b.created_at ? 0 : a.created_at > b.created_at ? 1 : -1),
        )

        store.setProp("messages", allMessages)

        return { error: null }
      }
    },
    async createChat(
      payload:
        | {
            type: ChatTypeEnum.BUYER
            buyer_user_id: string
            listing_id: string
          }
        | { type: ChatTypeEnum.AI }
        | { type: ChatTypeEnum.TEAM; team_id: string },
    ) {
      const result: {
        error?: string | null
        errorDetails?: string | null
        chatId: string | null
      } = {
        chatId: null,
      }
      const { error, data } = await chatsCreateChat({
        body: payload,
      })

      if (error) {
        result.error = "Failed to create chat"
        result.errorDetails = Array.isArray(error.detail)
          ? error.detail.map((e) => e.msg).join(", ")
          : error.detail
      } else if (data) {
        result.chatId = data.id
      }

      return result
    },
  }))
  .views((store) => ({
    profileByUserId(userId: string) {
      return store.profiles.find((p) => p.user_id === userId)
    },
    chatById(id: string) {
      return store.chats.find((c) => c.id === id)
    },
    listingChats(listing_id: string) {
      return store.chats.filter((c) => c.listing_id === listing_id)
    },
    listingBuyerChat({ listing_id, buyer_user_id }: { listing_id: string; buyer_user_id: string }) {
      return store.chats.find(
        (c) => c.listing_id === listing_id && c.buyer_user_id === buyer_user_id,
      )
    },
    get aiChat() {
      return store.chats.find((c) => c.type === ChatTypeEnum.AI)
    },
    get activeChat() {
      return store.chats.find((c) => c.id === store.activeChatId) ?? null
    },
    get sortedChats() {
      return store.chats
        .filter((c) => c.type !== ChatTypeEnum.AI)
        .sort((a, b) => {
          const aDate = a.updated_at ?? a.created_at
          const bDate = b.updated_at ?? b.created_at

          return aDate === bDate ? 0 : aDate > bDate ? -1 : 1
        })
    },
    messageById(id: string) {
      return store.messages.find((m) => m.id === id)
    },
    sortedMessages({ chatId, reverse = false }: { chatId: string; reverse?: boolean }) {
      const messages = chatId
        ? store.messages.filter((m) => m.chat_id === chatId)
        : store.messages.slice()

      return reverse
        ? messages.sort((a, b) =>
            a.created_at === b.created_at ? 0 : a.created_at > b.created_at ? -1 : 1,
          )
        : messages.sort((a, b) =>
            a.created_at === b.created_at ? 0 : a.created_at > b.created_at ? 1 : -1,
          )
    },
  }))

export interface ChatStore extends Instance<typeof ChatStoreModel> {}
export interface ChatStoreSnapshot extends SnapshotOut<typeof ChatStoreModel> {}
