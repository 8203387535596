import { DateTime } from "luxon"
import { useEffect, useState } from "react"
import { io, Socket } from "socket.io-client"
import { ChatStore, MsgModel, NotificationModel, NotificationStore } from "../models"

/**
 * Chat and Notification realtime updates
 */
export function useRealtime({
  host,
  chatStore,
  notificationStore,
  getIdToken,
  loggedIn,
}: {
  host: string
  chatStore: ChatStore
  notificationStore: NotificationStore
  getIdToken: (refresh?: boolean) => Promise<string | null>
  loggedIn: boolean
}) {
  const [websocket, setWebSocket] = useState<Socket | null>(null)

  useEffect(() => {
    // Fetch notification messages every minute to make sure we
    // dont miss any if there is an issue with the socket connection
    const interval = setInterval(() => {
      notificationStore.fetchNotifications({ limit: 10 })
    }, 60 * 1000)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    async function socketConnect() {
      const token = await getIdToken()
      if (!token) {
        console.log("RT: Unable to get user token")
        return
      }

      const socket = io(host, {
        withCredentials: true,
        auth: { token },
      })
      setWebSocket(socket)

      socket.on("connect", () => {
        console.log(`Socket Connected: ${socket.id}`)

        socket.on("msg", async (payload) => {
          if (payload.collection === "messages" && payload.op === "insert") {
            const doc = payload.doc

            chatStore.setProp("messages", [
              ...chatStore.messages.filter((r) => r.id !== doc._id),
              MsgModel.create({
                id: doc._id,
                text: doc.text,
                created_at: DateTime.fromISO(doc.created_at, { zone: "utc" }).toJSDate(),
                updated_at: doc.updated_at
                  ? DateTime.fromISO(doc.updated_at, { zone: "utc" }).toJSDate()
                  : null,
                user_id: doc.user_id,
                chat_id: doc.chat_id,
                attachments: doc.attachments,
                reactions: doc.reactions?.map(
                  (r: { created_at: string; user_id: string; emoji: string }) => ({
                    ...r,
                    created_at: DateTime.fromISO(r.created_at, { zone: "utc" }).toJSDate(),
                  }),
                ),
              }),
            ])
          } else if (payload.collection === "notifications") {
            const doc = payload.doc

            notificationStore.setProp("notifications", [
              ...notificationStore.notifications.filter((n) => n.id !== doc._id),
              NotificationModel.create({
                id: doc._id,
                created_at: DateTime.fromISO(doc.created_at, { zone: "utc" }).toJSDate(),
                updated_at: doc.updated_at
                  ? DateTime.fromISO(doc.updated_at, { zone: "utc" }).toJSDate()
                  : null,
                title: doc.title,
                body: doc.body,
                type: doc.data.type,
                screen: doc.data.screen,
                active_id: doc.data.active_id,
                secondary_id: doc.data.secondary_id,
                ack: doc.ack,
                realtime: true,
              }),
            ])
          }
        })
      })

      socket.on("disconnect", async () => {
        console.log(`Socket disconnected: ${socket.id}`)
        if (getIdToken) {
          const token = await getIdToken()
          if (token) {
            // @ts-expect-error sending token
            socket.auth.token = token
          }
        }
      })

      socket.on("reconnect_attempt", () => {
        console.log(`Socket reconnecting`)
      })

      socket.on("connect_error", async (error) => {
        if (getIdToken) {
          const token = await getIdToken()
          if (token) {
            // @ts-expect-error sending token
            socket.auth.token = token
          }
        }

        console.log(`Socket connection error`)
        console.error(error)

        if (socket.active) {
          console.log("Socket auto reconnect")
        } else {
          setTimeout(() => {
            console.log("Socket manual reconnect")
            socket.connect()
          }, 1000)
        }
      })
    }

    if (loggedIn && !websocket?.active) {
      socketConnect()
    }
  }, [loggedIn])

  return null
}
