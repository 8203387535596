import { DateTime } from "luxon"
import { Instance, SnapshotOut, applySnapshot, types } from "mobx-state-tree"
import { notificationsAckNotifications, notificationsGetNotifications } from "../../services"
import { withSetPropAction } from "../withSetPropAction"
import { Notification, NotificationModel } from "./Notification"

export interface NotificationData {
  type?: string
  screen?: string
  active_id?: string
  secondary_id?: string
}

export const NotificationStoreModel = types
  .model("NotificationStore")
  .props({
    notifications: types.array(NotificationModel),
  })
  .actions(withSetPropAction)
  .actions((store) => ({
    reset() {
      applySnapshot(store, {})
    },
  }))
  .actions((store) => ({
    addNotification(doc: Notification) {
      store.notifications.push(doc)
    },
    addNotifications(notifications: Notification[]) {
      store.notifications.push(...notifications)
    },
    removeNotification(doc: Notification) {
      store.notifications.remove(doc)
    },
  }))
  .actions((store) => ({
    async fetchNotifications({ limit = 50 }: { limit?: number }) {
      const result: { error: string | null; errorDetails?: string | null } = { error: null }

      const { error, data } = await notificationsGetNotifications({ query: { limit } })

      if (error) {
        result.errorDetails = Array.isArray(error.detail)
          ? error.detail.map((e: any) => e.msg).join(", ")
          : error.detail

        result.error = "Failed to fetch notifications"
        return result
      }

      if (data) {
        const notificationData = data.map((notification) => {
          return NotificationModel.create({
            ...notification,
            ...notification.data,
            created_at: DateTime.fromISO(notification.created_at, { zone: "utc" }).toJSDate(),
            updated_at: notification.updated_at
              ? DateTime.fromISO(notification.updated_at, { zone: "utc" }).toJSDate()
              : null,
          })
        })

        store.setProp("notifications", notificationData)
      }

      return result
    },
  }))
  .views((store) => ({
    get sortedNotifications() {
      return store.notifications
        .filter((l) => !l.ack)
        .sort((a, b) => (a.created_at === b.created_at ? 0 : a.created_at > b.created_at ? -1 : 1))
    },
    get realtimeNotificationId() {
      const notify = store.notifications.find((l) => l.realtime && !l.ack)
      return notify?.id ?? null
    },
    notificationById(id: string) {
      return store.notifications.find((n) => n.id === id)
    },
    pendingChatNotifications(chat_id: string) {
      const notify_ids: string[] = []
      for (const n of store.notifications) {
        if (n.type === "chat" && !n.ack && n.secondary_id === chat_id) {
          notify_ids.push(n.id)
        }
      }

      return notify_ids
    },
    pendingChats() {
      const chat_ids: string[] = []
      for (const n of store.notifications) {
        if (n.type === "chat" && !n.ack && n.secondary_id) {
          chat_ids.push(n.secondary_id)
        }
      }

      return chat_ids
    },
    pendingChat(chat_id: string) {
      let isPending = false
      for (const n of store.notifications) {
        if (n.type === "chat" && !n.ack && n.secondary_id === chat_id) {
          isPending = true
          break
        }
      }

      return isPending
    },
  }))
  .actions((store) => ({
    async ackNotifications({ ids }: { ids: string[] }) {
      const result: { error: string | null; errorDetails?: string | null } = { error: null }

      const { error } = await notificationsAckNotifications({ body: { ids } })

      if (error) {
        result.errorDetails = Array.isArray(error.detail)
          ? error.detail.map((e: any) => e.msg).join(", ")
          : error.detail

        result.error = "Failed to ack notifications"
      } else {
        for (const notify of store.notifications) {
          if (ids.includes(notify.id)) {
            notify.setProp("ack", true)
          }
        }
      }

      return result
    },
  }))

export interface NotificationStore extends Instance<typeof NotificationStoreModel> {}
export interface NotificationStoreSnapshot extends SnapshotOut<typeof NotificationStoreModel> {}
